import React, { useContext } from "react";

/// React router dom
import { Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
/// Dashboard
import Home from "./components/Dashboard/Home";

/////Demo pages
//import Demo6 from './components/Dashboard/Demo/Demo6';

/// App

/// Product List

/// Charts

/// Bootstrap

/// Plugins

//Redux

/// Widget

/// Table

/// Form

/// Pages
import { useSelector } from "react-redux";
import { ThemeContext } from "../context/ThemeContext";
import Setting from "./layouts/Setting";
import Packges from "./pages/Packages";
import Empolye from "./components/table/FilteringTable/Empolyee";

const Markup = () => {
  const user = useSelector((state) => state.auth.auth);
  console.log(user);

  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "*", component: Home },
    { url: "dashboard", component: Home },
    { url: "users", component: Empolye },
    // { url: "module", component: Module },
    // { url: "sub-module", component: SubModule },
    { url: "Packages", component: Packges },
  ];

  return (
    <>
      <div
        id={`${user?.status === "paid" ? "main-wrapper" : ""}`}
        className={`${user?.status === "paid" ? "show" : "vh-100 "}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        <Nav status={user?.status} />

        <div
          className={`${
            user?.status === "paid"
              ? "content-body"
              : "d-flex align-items-center justify-content-center content-body ms-0"
          }`}
        >
          <div
            className={`${
              user?.status === "paid" ? "container-fluid" : "container"
            }`}
          >
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={<data.component />}
                />
              ))}
            </Routes>
          </div>
        </div>
        {user?.status === "paid" && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
