import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import { axiosInstance } from "../../services/AxiosInstance";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import "../packages.css";
import { FaCheckCircle } from "react-icons/fa";

const Packges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [packages, setPackage] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.auth.auth);
  const [ischeck, setIscheck] = useState(false);
  const handleCheck = (event) => {
    const { checked } = event.target;
    setIscheck(checked);
  };
  const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  const onToken = (token, id, amount) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("source", token?.id);
    formdata.append("company_id", user?.localId);
    formdata.append("amount", amount);
    formdata.append("package_id", id);

    axiosInstance
      .post("company/package/buy", formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          toast.success(response.data.message);
          const updatedUser = {
            ...user,
            status: "paid",
          };
          console.log(updatedUser);
          dispatch(loginConfirmedAction(updatedUser));
          navigate("/");
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    setLoader(true);
    axiosInstance
      .get("company/package/view")
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setPackage(response?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <h1 className="text-center">Packages</h1>
      <div className="d-flex justify-content-end items-center gap-2">
        <p>Montly</p>
        <input
          class="switch"
          checked={ischeck}
          onClick={handleCheck}
          type="checkbox"
        />
        <p>Yearly</p>
      </div>
      <div className="row justify-content-center">
        {packages
          .filter((s) => s.package_type === (ischeck ? "Yearly" : "Monthly"))
          .map((e) => {
            const amountStipe= e.package_type === "Yearly"
                ? e?.package_amount * e.package_users * 12 
                : e?.package_amount * e.package_users ;
            const finalAmount =
              e.package_type === "Yearly"
                ? e?.package_amount * e.package_users * 12 * 100
                : e?.package_amount * e.package_users * 100;
            return (
              <div className="col-md-6 col-sm-6 col-lg-4 mb-3">
                <div className="authincation-content ">
                  <div className="auth-form position-relative">
                    <h2 className="text-center mb-4 ">{e.plan}</h2>

                    <div className="form-group">
                      <h2 className="">
                        <span className="fs-2 text-success">$</span>
                        {e.package_amount}
                        <span className="fs-5 ">/{e.package_type}</span>
                      </h2>
                      <h5>
                        <FaCheckCircle className="text-success fs-4" />
                        &nbsp; For {e.package_users} Users
                      </h5>
                      <p>{e.description}</p>
                    </div>
                    <div className="text-center mt-5">
                      <StripeCheckout
                        name={user?.role}
                        email={user?.email}
                        token={(token) => onToken(token, e?.id, amountStipe)}
                        stripeKey={stripeKey}
                        amount={finalAmount}
                        currency="USD"
                      >
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Buy Now
                        </button>{" "}
                      </StripeCheckout>
                    </div>
                    {e?.recommended === "true" && (
                      <div
                        className="bg-warning badge position-absolute"
                        style={{ right: "4%", top: "4%" }}
                      >
                        Recommended
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Packges;
