// employeeSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/AxiosInstance";

// Create an async thunk to fetch employee data
export const fetchemployeeData = createAsyncThunk(
  "employee/fetchemployeeData",
  async () => {
    try {
      const response = await axiosInstance.get("company/employee/view");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    data: [],
    employeeCount: 0,
    loading: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchemployeeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchemployeeData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.employeeCount = action.payload.length;
      })
      .addCase(fetchemployeeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default employeeSlice.reducer;
