import { toast } from "react-toastify";
import {
  formatError,
  login,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(
  email,
  password,
  company_name,
  owner_name,
  phone_no,
  description,
  history
) {
  return (dispatch) => {
    signUp(
      email,
      password,
      company_name,
      owner_name,
      phone_no,
      description,
      history
    )
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          toast.success(response?.data?.message);
          window.location.pathname = "/login";
        } else {
          toast.error(response?.data.message);
          const errorMessage = formatError(response.data.message);
          dispatch(signupFailedAction(errorMessage));
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error?.response?.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.clear();
  history("/login");
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        console.log(response, "res");
        if (response.data.status) {
          saveTokenInLocalStorage(response?.data?.token);
          dispatch(loginConfirmedAction(response?.data));
          toast.success(response?.data?.message);
          if (response.data.data.status === "unpaid") {
            history("/Packages");
          } else {
            history("/");
          }
        } else {
          toast.error(response?.data.message);
          const errorMessage = formatError(response.data.message);
          dispatch(loginFailedAction(errorMessage));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data.message);

        const errorMessage = formatError(error.response.data.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
